import { Box, CircularProgress, Typography } from '@material-ui/core'
import {
  React, useEffect
} from 'react'
import { colors } from '../utils/elements';

const Loading = ({ setShowHeader }) => {
  useEffect(() => {
    setShowHeader(false);
  })
  return (
    <div style={{ zIndex: 1 }}>
      <Box mt={"180px"} display="flex" justifyContent="center" textAlign="center">
        <Typography variant="h2" style={{ fontWeight: "bold", color: colors.white }}>
          답변을 토대로<br />
          브라를 연구중입니다.
        </Typography>
      </Box>
      <Box mt={3} display="flex" justifyContent="center">
        <CircularProgress style={{ color: "white" }}>
        </CircularProgress>
      </Box>
      <Box style={{
        top: 0,
        position: "absolute",
        zIndex: -1,
        height: "100vh",
        width: "100vw",
        maxWidth: '640px',
        backgroundImage: `url("/assets/images/common/loading_bg.png")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }} />
    </div>
  )
}
export default Loading