import {
  React,
} from 'react';

import {
  BCContainer,
  BCQuestionsTitle,
  BCShowSlider
} from "../../utils/elements"

import { strap } from "../../data/fit"
import { Box } from '@material-ui/core';
import { loadImage } from '../../utils/imageLoader';

export const useQFitStrapAnswers = () => {
  return strap[0]
}

export const checkQFitStrap = (answers) => {
  return answers !== null
}

export const QFitStrap = ({ answers, setAnswers }) => {
  loadImage(strap, "/assets/images/fit/")
  return (
    <>
      <BCQuestionsTitle>속옷을 입은 후<br />어깨끈은 어떤 모습인가요?</BCQuestionsTitle>
      <Box mt={2} />

      <BCContainer>
        <BCShowSlider
          choices={strap}
          current={answers ? answers : strap[0]}
          onChange={(e, value) => { setAnswers(strap[value]) }}
          imagePrefix={"/assets/images/fit/"}
        />
      </BCContainer>
    </>
  )
}