import {
  React,
  useState
} from 'react';

import { BCHeader } from "./utils/elements"

import { Route, Switch, Redirect } from 'react-router-dom'

import { QuestionsProvider } from './contexts/Questions';

import Questions from './components/Questions'
import Result from './components/Result'
import Start from './components/Start';
import Home from './components/Home';
import Shared from './components/Shared';
import Review from './components/Review';
import Complete from './components/Complete';

const App = () => {

  const [showHeader, setShowHeader] = useState(false)
  const [qType, setQType] = useState("")
  const [progress, setProgress] = useState(2)
  const [result, setResult] = useState(null)
  const [needSearch, setNeedSearch] = useState(true);
  const [eventOK, setEventOK] = useState(false);

  return (
    <>
      {
        showHeader ? <BCHeader progress={progress} /> : null
      }
      <QuestionsProvider>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Home setShowHeader={setShowHeader} />}
          />
          <Route
            exact
            path="/start"
            render={() => <Start qType={qType} setQType={setQType} setProgress={setProgress} setShowHeader={setShowHeader}
              setNeedSearch={setNeedSearch} setEventOK={setEventOK}
            />}
          />
          <Route
            exact
            path={["/shared"]}
            render={() => <Shared qType={qType} setProgress={setProgress} setShowHeader={setShowHeader}
              setNeedSearch={setNeedSearch} needSearch={needSearch} result={result} setResult={setResult} />}
          />
          {qType === "" ? <Redirect to='/' /> :
            <>
              <Route
                exact
                path={["/questions"]}
                render={() => <Questions qType={qType} setProgress={setProgress}
                  setShowHeader={setShowHeader} />}
              />
              <Route
                exact
                path={["/result"]}
                render={() => <Result qType={qType} setProgress={setProgress}
                  isShared={false} setShowHeader={setShowHeader}
                  setNeedSearch={setNeedSearch} needSearch={needSearch} result={result} setResult={setResult}
                  eventOK={eventOK} />}
              />
              {/* <Route
                exact
                path={["/review"]}
                render={() => <Review qType={qType} setProgress={setProgress} setShowHeader={setShowHeader} />}
              /> */}
              <Route
                exact
                path={["/complete"]}
                render={() => <Complete qType={qType} setProgress={setProgress} setShowHeader={setShowHeader} setEventOK={setEventOK} />}
              />
            </>
          }
        </Switch>
      </QuestionsProvider>
    </>
  );
}

export default App;