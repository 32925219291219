import {
  React, useEffect
} from 'react'

import {
  BCContainer,
  BCCouponButton,
  BCToResultButton
} from "../utils/elements"

import { Box, Typography } from '@material-ui/core'

const Complete = ({ setShowHeader, setProgress, setEventOK }) => {
  useEffect(() => {
    setShowHeader(true);
  });
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box pt={12} style={{ textAlign: "center" }}>
          <img width="140px" src="/assets/images/common/finish.svg" alt="complete" />
          <Box mt={"12px"} />
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            브라 연구 완료!
          </Typography>
        </Box>
      </Box>
      <Box mt={7} />
      <BCContainer>
        <BCCouponButton to="http://thezam.co.kr/exec/front/newcoupon/IssueDownload?coupon_no=6070738473300000188"
          onClick={() => { setEventOK(false); }} />
        <Box mt={"12px"} />
        <BCToResultButton />
      </BCContainer>
    </>
  )
}
export default Complete