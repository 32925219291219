import {
  React,
  createContext,
  useState
} from 'react'
import { QShapeMain, useQShapeMainAnswers, checkQShapeMain } from '../components/questions/shape-main'
import { QShapeSub, useQShapeSubAnswers, checkQShapeSub } from '../components/questions/shape-sub'
import { QFitBreast, useQFitBreastAnswers, checkQFitBrest } from '../components/questions/fit-breast'
import { QFitStrap, useQFitStrapAnswers, checkQFitStrap } from '../components/questions/fit-strap'
import { QFitBand, useQFitBandAnswers, checkQFitBand } from '../components/questions/fit-band'
import { QSize, useQSizeAnswers, checkQSize } from '../components/questions/size'
import { QPrefFunc, useQPrefFuncAnswers, checkQPrefFunc } from '../components/questions/pref-func'
import { QPrefWire, useQPrefWireAnswers, checkQPrefWire } from '../components/questions/pref-wire'
import { QPrefDesign, useQPrefDesignAnswers, checkQPrefDesign } from '../components/questions/pref-design'

const QuestionsContext = createContext();

const QuestionsProvider = (props) => {

  const [QShapeMainAnswers, setQShapeMainAnswers] = useState(useQShapeMainAnswers());
  const [QShapeSubAnswers, setQShapeSubAnswers] = useState(useQShapeSubAnswers());
  const [QFitBreastAnswers, setQFitBreastAnswers] = useState(useQFitBreastAnswers());
  const [QFitStrapAnswers, setQFitStrapAnswers] = useState(useQFitStrapAnswers());
  const [QFitBandAnswers, setQFitBandAnswers] = useState(useQFitBandAnswers());
  const [QSizeAnswers, setQSizeAnswers] = useState(useQSizeAnswers());
  const [QPrefFuncAnswers, setQPrefFuncAnswers] = useState(useQPrefFuncAnswers());
  const [QPrefWireAnswers, setQPrefWireAnswers] = useState(useQPrefWireAnswers());
  const [QPrefDesignAnswers, setQPrefDesignAnswers] = useState(useQPrefDesignAnswers());

  const qMap = {
    "a": {
      2: { Q: QShapeMain, A: QShapeMainAnswers, setA: setQShapeMainAnswers, check: checkQShapeMain, key: "shape_main" },
      3: { Q: QShapeSub, A: QShapeSubAnswers, setA: setQShapeSubAnswers, check: checkQShapeSub, key: "shape_sub" },
      4: { Q: QSize, A: QSizeAnswers, setA: setQSizeAnswers, check: checkQSize, key: "size" },
      5: { Q: QFitBreast, A: QFitBreastAnswers, setA: setQFitBreastAnswers, check: checkQFitBrest, key: "fit_breast" },
      6: { Q: QFitStrap, A: QFitStrapAnswers, setA: setQFitStrapAnswers, check: checkQFitStrap, key: "fit_strap" },
      7: { Q: QFitBand, A: QFitBandAnswers, setA: setQFitBandAnswers, check: checkQFitBand, key: "fit_band" },
      8: { Q: QPrefFunc, A: QPrefFuncAnswers, setA: setQPrefFuncAnswers, check: checkQPrefFunc, key: "pref_func" },
    },
    "b": {
      2: { Q: QPrefFunc, A: QPrefFuncAnswers, setA: setQPrefFuncAnswers, check: checkQPrefFunc, key: "pref_func" },
      3: { Q: QPrefWire, A: QPrefWireAnswers, setA: setQPrefWireAnswers, check: checkQPrefWire, key: "pref_wire" },
      4: { Q: QPrefDesign, A: QPrefDesignAnswers, setA: setQPrefDesignAnswers, check: checkQPrefDesign, key: "pref_design" },
      5: { Q: QSize, A: QSizeAnswers, setA: setQSizeAnswers, check: checkQSize, key: "size" },
    }
  }

  return (
    <QuestionsContext.Provider value={qMap}>
      {props.children}
    </QuestionsContext.Provider>
  )
}

export { QuestionsContext, QuestionsProvider };