import {
  React, useEffect,
} from 'react'

import {
  BCContainer,
  BCStartButton,
  BCHomeTitle,
  BCHomeDesc,
  BCHomeLoginDesc,
  colors,
  BCLoginButton
} from "../utils/elements"

import { Box } from '@material-ui/core'

const Home = ({ setShowHeader }) => {
  useEffect(() => {
    setShowHeader(false);
  });

  return (
    <>
      <Box mt={"50px"} />
      <BCHomeTitle />
      <Box mt={"28px"} mb={"26px"} display={"flex"} justifyContent={"center"} >
        <img width={"240px"} src='/assets/images/common/home_bg.svg' alt="title" />
      </Box>
      <BCHomeDesc>
        체형에 알맞는 제품과 사이즈를 제안하는<br />
        맞춤형 속옷 추천서비스 
        
      </BCHomeDesc>
      <Box mt={3} />
      <BCHomeDesc>
        브라연구소에 오신 자몽님, 반갑습니다.
      </BCHomeDesc>
      <Box mt={"36px"} />

      <BCContainer>
        <BCStartButton to="/start" color={colors.point2}>
          1분만에 맞춤 속옷 추천 받기
        </BCStartButton>
        <Box mt={"12px"} />
        <BCLoginButton to="https://thezam.co.kr/member/login.html">
          로그인하기
        </BCLoginButton>
        <Box mt={1} />

        <BCHomeLoginDesc>
          테스트 완료 후 원활한 쿠폰 다운로드를 위해<br />
          현 단계에서의 로그인을 권장합니다.
        </BCHomeLoginDesc>
      </BCContainer>
    </>
  )
}
export default Home
