import React from 'react';

import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Slider,
  withStyles,
  LinearProgress,
  Grid,
} from '@material-ui/core';

import { Link } from 'react-router-dom'
import Select, { components } from "react-select";

const colors = {
  disabled: "#aeaeae",
  primary: "#202020",
  border: "#e5e5e5",
  white: "#ffffff",
  body: "#757575",
  title: "#424242",
  point: "#c09c7c",
  point2: "#422d1b",
  party: "#ff8888",
}

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 2,
  },
  colorPrimary: {
    backgroundColor: colors.border,
  },
  bar: {
    backgroundColor: colors.point2,
  },
}))(LinearProgress);

const BCHeader = ({ progress }) => {
  return (
    <React.Fragment>
      <Box mt={10} />
      <Box
        height={"50px"}
        top={0}
        position="fixed"
        width="100vw"
        maxWidth="640px"
        alignItems="center"
        justifyContent="center"
        zIndex={1}
        style={{ backgroundColor: colors.white }}
      >
        <Box
          height={"100%"}
          width="fit-content"
          display="flex"
          alignItems="center"
          mx={"auto"}
        >
          <img height="16px" src='/assets/images/common/navigation_bar_title.svg' alt="title" />
        </Box>
        <Box>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
    </React.Fragment>
  )
}

const BCFooter = (props) => {
  return (
    <React.Fragment>
      <Box mt={26} />
      <Box
        position="fixed"
        top="auto"
        bottom={0}
        width="100vw"
        maxWidth="640px"
        borderTop={1}
        borderColor={colors.border}
        style={{ backgroundColor: colors.white }}
        zIndex={1}
      >
        {props.children}
      </Box>
    </React.Fragment>
  )
}

const BCHomeTitle = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Box display="flex" justifyContent="center">
          <img width={"114px"} src='/assets/images/common/home_logo.svg' alt="title" />
        </Box>
        <Box ml={1} display="flex" justifyContent="center" >
          <Typography variant="h2" style={{ color: colors.point2, fontWeight: "bold" }}>브라 연구소</Typography>
        </Box>
      </Box>
    </>
  )
}

const BCHomeDesc = (props) => {
  return (
    <>
      <Box display="flex" justifyContent="center" flexWrap="wrap"
        style={{ textAlign: "center" }}>
        <Typography variant="body2" style={{
          color: colors.primary,
          lineHeight: "24px",
        }}>
          {props.children}
        </Typography>
      </Box>
    </>
  )
}

const BCHomeLoginDesc = (props) => {
  return (
    <>
      <Box display="flex" justifyContent="center" flexWrap="wrap"
        style={{ textAlign: "center" }}>
        <Typography variant="caption" style={{
          color: colors.body,
        }}>
          {props.children}
        </Typography>
      </Box>
    </>
  )
}

const BCQuestionsNumber = (props) => {
  return (
    <Box mt={"20px"} textAlign="center">
      <Typography variant="h2" style={{ color: colors.point }}>
        {props.children}
      </Typography>
    </Box>
  )
}

const BCQuestionsTitle = (props) => {
  return (
    <Box mt={"10px"} textAlign="center">
      <Typography variant="h2">
        {props.children}
      </Typography>
    </Box>
  )
}

const BCQuestionsInfo = (props) => {
  return (
    <Box mt={1} textAlign="center">
      <Typography variant="body1" style={{ color: colors.body }}>
        {props.children}
      </Typography>
    </Box>
  )
}

const BCReviewInfo = (props) => {
  return (
    <Box mt={1} textAlign="center">
      <Typography variant="body2" style={{ color: colors.body }}>
        {props.children}
      </Typography>
    </Box>
  )
}

const BCReviewTextArea = ({ reviewValue, setReviewValue }) => {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <textarea style={{
          resize: 'none',
          width: '100%',
          height: '248px',
          fontFamily: 'Noto Sans KR',
          border: '1px solid ' + colors.border,
        }}
          value={reviewValue}
          onChange={(e) => { setReviewValue(e.target.value) }}>
        </textarea>
      </Box>
      <Typography style={{
        fontSize: "12px",
        lineHeight: "18px",
        color: colors.body,
        textAlign: "right"
      }}>{reviewValue.length}자</Typography>
    </>
  )
}

const BCContainer = (props) => {
  return (
    <Box m={2}>
      {props.children}
    </Box>
  )
}

const BCQuestionsSelect = ({ options, values, imagePrefix, onChange }) => {
  const Option = props => (
    <Box width={"48%"}>
      <components.Option {...props}>
        <Card
          variant={"outlined"}
          style={{
            boxSizing: "border-box",
            borderRadius: "10px",
            borderColor: props.isSelected ? colors.point2 : colors.border,
            borderWidth: "1px",
            height: "132px",
            textAlign: "center",
          }}
        >
          <CardContent>
            <Box
              width="fit-content"
              mb={"14px"}
              mx={"auto"}
            >
              <img src={imagePrefix + props.data.icon} alt="icon" />
            </Box>
            <Typography
              variant="body1"
              style={{
                color: props.isSelected ? colors.point2 : colors.body,
                fontWeight: props.isSelected ? "bold" : null,
              }}
              className="display-linebreak">
              {props.data.label}
            </Typography>
          </CardContent>
        </Card>
      </components.Option>
    </Box >
  );

  return (
    <Select
      hideSelectedOptions={false}
      menuIsOpen={true}
      components={{ MenuList: BCMenuList, Option: Option }}
      styles={bcSelectStyles}
      isSearchable={false}
      options={options}
      value={values}
      onChange={onChange}
    />
  )
}

const BCNavigation = (props) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      height="55px"
    >
      <BCLink disabled={props.backDisabled || !props.backLink} to={props.backLink}>
        <Button disabled={props.backDisabled} style={{ height: "100%" }} onClick={props.backAction}>
          <Box
            display="inline-block"
            px={1}
          >
            <img
              src={props.backDisabled ? "/assets/images/common/icon_arrow_back_disabled.svg" : "/assets/images/common/icon_arrow_back.svg"}
              style={{ float: 'left' }} alt="back" />
            <Typography variant="body1"
              style={{
                float: 'left', lineHeight: '22px', marginLeft: '4px',
                color: props.backDisabled ? colors.disabled : colors.point2,
                fontWeight: props.backDisabled ? null : 'bold'
              }}
            >
              이전
            </Typography>
          </Box>
        </Button>
      </BCLink>

      <BCLink disabled={props.nextDisabled || !props.nextLink} to={props.nextLink}>
        <Button disabled={props.nextDisabled} style={{ height: "100%" }} onClick={props.nextAction}>
          <Box
            display="inline-block"
            px={1}
          >
            <Typography variant="body1"
              style={{
                float: 'left', lineHeight: '22px', marginRight: '4px',
                color: props.nextDisabled ? colors.disabled : colors.point2,
                fontWeight: props.nextDisabled ? null : 'bold'
              }}>
              다음
            </Typography>
            <img
              src={props.nextDisabled ? "/assets/images/common/icon_arrow_next_disabled.svg" : "/assets/images/common/icon_arrow_next.svg"}
              style={{ float: 'left' }} alt="next" />
          </Box>
        </Button>
      </BCLink >
    </Box >

  );
}

const BCLink = (props) => {
  return (
    props.disabled ?
      props.children :
      <Link
        to={props.to}
        style={{ textDecoration: 'none' }}
      >
        {props.children}
      </Link>

  )
}

const BCGallerySelect = ({ smallText, options, values, onChange, imagePrefix, isMulti, selectLimit }) => {

  const Option = props => (
    <Box width={"48%"}>
      <components.Option {...props}>
        <Card
          variant={"outlined"}
          style={{
            boxSizing: "border-box",
            borderRadius: "10px",
            borderColor: props.isSelected ? colors.point2 : colors.border,
            borderWidth: "1px",
            textAlign: "center",
          }}
        >
          <Box pt="6px" px="4px">
            <img width="100%" src={imagePrefix + props.data.icon} alt="icon"></img>
          </Box>
          <Box
            pt={"6px"}
            style={{
              height: smallText ? '46px' : '36px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {
              smallText ?
                <Typography variant="body2"
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: props.isSelected ? colors.point2 : colors.body,
                    fontWeight: props.isSelected ? "bold" : null,
                  }}
                  className="display-linebreak"
                >
                  {props.data.label}
                </Typography>
                :
                <Typography variant="body2"
                  style={{
                    color: props.isSelected ? colors.point2 : colors.body,
                    fontWeight: props.isSelected ? "bold" : null,
                  }}
                  className="display-linebreak"
                >
                  {props.data.label}
                </Typography>
            }
          </Box>
        </Card>
      </components.Option>
    </Box>
  );

  return (
    <Select
      imgePrefix={imagePrefix}
      hideSelectedOptions={false}
      menuIsOpen={true}
      components={{ MenuList: BCMenuList, Option: Option }}
      styles={bcSelectStyles}
      isMulti={isMulti}
      isSearchable={false}
      options={options}
      value={values}
      onChange={
        selectedOption => {
          if (!isMulti || selectedOption == null || selectedOption.length <= selectLimit) {
            onChange(selectedOption)
          }
        }
      }
    />
  )
}

const BCSizeHeader = ({ isBust, setIsBust, answers, setAnswers }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderBottom={1}
      style={{
        borderColor: colors.border
      }}
    >
      <Box
        ml={5}
        width="108px"
        textAlign="center"
      >
        <Button style={{
          backgroundColor: colors.white,
          padding: 0,
          display: "block",
          width: "100%",
          height: "100%"
        }}
          onClick={() => {
            setIsBust(true)
          }}
        >
          <Box mb={"12px"}>
            <Typography variant="h1"
              style={{ color: answers.value.bust ? colors.point2 : colors.border }}
            >{answers.value.bust ? answers.value.bust.label : 65}</Typography>
          </Box>
          <Box mb={"12px"}>
            <Typography variant="body2">가슴 둘레</Typography>
          </Box>
        </Button>
        {isBust ?
          <Box
            mt={"-1px"}
            mx={"auto"} width="56px" borderBottom={3}>
          </Box>
          :
          null
        }
      </Box>

      <Box
        mr={5}
        width="108px"
        textAlign="center"
      >
        <Button style={{
          backgroundColor: colors.white,
          padding: 0,
          display: "block",
          width: "100%",
          height: "100%"
        }}
          onClick={() => {
            setAnswers({
              value: answers.value,
              checked: true
            })
            setIsBust(false)
          }}
        >
          <Box mb={"12px"}>
            <Typography variant="h1"
              style={{ color: answers.value.cup ? colors.point2 : colors.border }}
            >{answers.value.cup ? answers.value.cup.label : "AA"}</Typography>
          </Box>
          <Box mb={"12px"}>
            <Typography variant="body2">컵 사이즈</Typography>
          </Box>
        </Button>
        {isBust ?
          null
          :
          <Box
            mt={"-1px"}
            mx={"auto"} width="56px" borderBottom={3}>
          </Box>
        }
      </Box>
    </Box>
  )
}

const BCSizeSelect = ({ options, values, onChange, isMulti, selectLimit }) => {

  const Option = props => (
    <Grid item xs={3} sm={3} style={{ marginBottom: "20px", padding: 0, display: "flex", justifyContent: "center" }}>
      <components.Option {...props}>
        <Card
          variant={"outlined"}
          style={{
            boxSizing: "border-box",
            borderRadius: "10px",
            borderColor: props.isSelected ? colors.point2 : colors.border,
            borderWidth: "1px",
            height: "18vw",
            width: "18vw",
            maxWidth: "115px",
            maxHeight: "115px",
            textAlign: "center",
            display: "flex",
          }}
        >
          <CardContent
            style={{
              width: "100%",
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="subtitle1"
              style={{
                color: props.isSelected ? colors.point2 : colors.body,
                fontWeight: props.isSelected ? "bold" : null,
              }}
            >
              {props.data.label}
            </Typography>
          </CardContent>
        </Card>
      </components.Option>
    </Grid>
  );

  return (
    <Select
      hideSelectedOptions={false}
      menuIsOpen={true}
      components={{ MenuList: BCMenuListGrid, Option: Option }}
      styles={bcGridSelectStyles}
      isMulti={isMulti}
      isSearchable={false}
      options={options}
      value={values}
      onChange={
        selectedOption => {
          onChange(selectedOption)
        }
      }
    />
  )
}

const BCMenuListGrid = props => (
  <components.MenuList {...props}>
    <Grid container spacing={1}>
      {props.children}
    </Grid>
  </components.MenuList>
);

const BCStartButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <BCLink {...props}>
        <Button
          style={{ width: "100%", height: "56px", backgroundColor: props.color ? props.color : colors.point, borderRadius: "10px" }}
        >
          <Typography variant="body1" style={{ fontWeight: "bold", color: colors.white }}>
            {props.children}
          </Typography>
        </Button>
      </BCLink>
    </Box >
  )
}

const BCTalkButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <Button
        className={"channelLauncher"}
        style={{ width: "100%", height: "56px", backgroundColor: props.color ? props.color : colors.point2, borderRadius: "10px" }}
      >
        <Typography variant="body1" style={{ color: colors.white, fontSize: "14px" }}>
          결과 바탕으로 <b>1:1 상담하기</b>
        </Typography>
        <img style={{ paddingTop: "2px" }} width="20px" src="/assets/images/common/icon_chevron_right.svg" alt="right" />
      </Button>
    </Box >
  )
}

const BCReviewSubmitButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <Button
        style={{ width: "100%", height: "56px", backgroundColor: props.color ? props.color : colors.point2, borderRadius: "10px" }}
        onClick={props.onClick}
      >
        <Typography variant="body1" style={{ color: colors.white, fontSize: "14px" }}>
          {props.children}
        </Typography>
      </Button>
    </Box >
  )
}

const BCLoginButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <a href={props.to} target="_blank" rel="noreferrer">
        <Button
          style={{ boxSizing: "border-box", width: "100%", height: "56px", backgroundColor: "none", borderRadius: "10px", border: "solid 1px " + colors.point2 }}
        >
          <Typography variant="body1" style={{ fontWeight: "bold", color: colors.point2 }}>
            {props.children}
          </Typography>
        </Button>
      </a>
    </Box >
  )
}

const BCResultReviewButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <BCLink {...props}>
        <Button
          style={{ width: "100%", height: "56px", backgroundColor: colors.party, borderRadius: "10px" }}
        >
          <Typography variant="body1" style={{ color: colors.white, fontSize: "14px" }}>
            <b>할인 쿠폰</b> 발급 받기
          </Typography>
          <img style={{ paddingTop: "2px" }} width="20px" src="/assets/images/common/icon_chevron_right.svg" alt="right" />
        </Button>
      </BCLink>
    </Box >
  )
}

const BCCouponButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <a href={props.to} target="_blank" rel="noreferrer">
        <Button
          onClick={props.onClick}
          style={{ width: "100%", height: "56px", backgroundColor: props.color ? props.color : colors.point2, borderRadius: "10px" }}
        >
          <Typography variant="body1" style={{ color: colors.white, fontSize: "14px" }}>
            <b>할인쿠폰</b> 사용하러 가기
          </Typography>
          <img width="20px" src="/assets/images/common/download.png" alt="download" />
        </Button>
      </a>
    </Box >
  )
}

const BCToResultButton = (props) => {
  return (
    <Box width="100%" style={{ textAlign: "center" }}>
      <BCLink to="/result">
        <Button
          style={{ boxSizing: "border-box", width: "100%", height: "56px", backgroundColor: "none", borderRadius: "10px", border: "solid 1px " + colors.point2 }}
        >
          <Typography variant="body1" style={{ color: colors.point2, fontSize: "14px" }}>
            결과 화면으로 돌아가기
          </Typography>
          <img style={{ paddingTop: "2px" }} width="20px" src="/assets/images/common/icon_chevron_right_point2.svg" alt="right" />
        </Button>
      </BCLink>
    </Box >
  )
}

const BCShowSliderComponent = withStyles({
  root: {
    color: colors.border,
    height: 10,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: colors.point2,
    marginTop: -7,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    opacity:0,
    height: 6,
    borderRadius: 4,
  },
  rail: {
    opacity:1,
    backgroundColor: "#F7F7F7",
    height: 6,
    borderRadius: 4,
  },
  mark: {
    opacity:1,
    borderRadius: 10,
    height: 20,
    width: 20,
    backgroundColor: "#ECECEC",
    marginTop: -7,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
})(Slider);

const BCShowSlider = ({ choices, current, onChange, imagePrefix }) => {

  let values = choices.map(choice => choice.value)

  return (
    <Box mt={1}>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Card
          variant={"outlined"}
          style={{ width: "240px", border: "0px" }}
        >
          <CardMedia
            image={imagePrefix + current.icon}
            style={{
              height: 0,
              paddingTop: '100%', // 16:9,
            }}
          />
          <Box display="flex" justifyContent="center">
            <Typography variant="body1" style={{ color: colors.point2, fontWeight: "bold" }}>
              {current.label}
            </Typography>
          </Box>
        </Card>
      </Box>
      <Box mt={"20px"} mx={2}>
        <BCShowSliderComponent
          value={current.value}
          onChange={onChange}
          step={1}
          min={0}
          max={values.length - 1}
          marks={true}
        />
      </Box>

    </Box >
  )
}

const BCButtonSelect = ({ options, values, onChange, isMulti }) => {
  const styles = {
    control: (css) => ({
      display: "none"
    }),
    menu: (css) => ({
      ...css,
      position: "relative",
      marginTop: '0px',
      boxShadow: null,
      zIndex: null,
    }),
    menuList: (css) => ({
      ...css,
      position: "relative",
      maxHeight: null,
      overflowY: null,
    }),
    option: (css) => ({
      ...css,
      backgroundColor: "transparent",
      '&:active': {
        backgroundColor: "transparent",
      }
    })
  };

  const MenuList = props => (
    <components.MenuList {...props}>
      <Box
        display="block"
      >
        {props.children}
      </Box>
    </components.MenuList>
  );

  const Option = props => (
    <Box
      pb="12px"
      width="100%"
      height='68px'
      textAlign="center"
    >
      <components.Option {...props}>
        <Card
          variant={"outlined"}
          style={{
            borderRadius: "10px",
            borderColor: props.isSelected ? colors.point2 : colors.border,
            padding: 0,
            height: "68px"
          }}
        >
          <CardContent
            style={{
              padding: 0,
              height: "100%"
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center"
              style={{
                height: "100%"
              }}>
              <Typography variant="body2"
                style={{
                  fontWeight: props.isSelected ? "bold" : null,
                  color: props.isSelected ? colors.point2 : colors.body
                }}
              >
                {props.data.label}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </components.Option>
    </Box >
  );

  return (
    <Select
      hideSelectedOptions={false}
      menuIsOpen={true}
      components={{ MenuList: MenuList, Option: Option }}
      styles={styles}
      isMulti={isMulti}
      isSearchable={false}
      options={options}
      value={values}
      onChange={onChange}
    />
  )
}

const BCBrandSelect = ({ options, values, onChange, isMulti, disabled }) => {
  const Option = props => (
    <Grid item xs={6}>
      <components.Option {...props}>
        <Card
          variant={"outlined"}
          style={{
            boxSizing: "border-box",
            borderRadius: "10px",
            borderColor: props.isSelected ? colors.point2 : colors.border,
            borderWidth: "1px",
            height: "48px",
            width: "100%",
            textAlign: "center",
            display: "flex",
          }}
        >
          <CardContent
            style={{
              width: "100%",
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="body2"
              style={{
                color: props.isSelected ? colors.point2 : colors.body,
                fontWeight: props.isSelected ? "bold" : null,
              }}
            >
              {props.data.label}
            </Typography>
          </CardContent>
        </Card>
      </components.Option>
    </Grid>
  );

  return (
    <Select
      isDisabled={disabled}
      hideSelectedOptions={false}
      menuIsOpen={true}
      components={{ MenuList: BCMenuListGrid, Option: Option }}
      styles={bcGridSelectStyles}
      isMulti={isMulti}
      isSearchable={false}
      options={options}
      value={values}
      onChange={
        selectedOption => {
          onChange(selectedOption)
        }
      }
    />
  )
}

const bcGridSelectStyles = {
  control: (css) => ({
    display: "none"
  }),
  menu: (css) => ({
    ...css,
    backgroundColor: "transparent",
    position: "relative",
    marginTop: '0px',
    boxShadow: null,
    zIndex: null,
  }),
  menuList: (css) => ({
    ...css,
    backgroundColor: "transparent",
    position: "relative",
    maxHeight: null,
    overflowY: null,
  }),
  option: (css) => ({
    ...css,
    padding: "0",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    '&:active': {
      backgroundColor: "transparent",
    }
  })
};

const bcSelectStyles = {
  control: (css) => ({
    display: "none"
  }),
  menu: (css) => ({
    ...css,
    position: "relative",
    marginTop: '0px',
    boxShadow: null,
    zIndex: null,
  }),
  menuList: (css) => ({
    ...css,
    position: "relative",
    maxHeight: null,
    overflowY: null,
  }),
  option: (css) => ({
    ...css,
    padding: "0",
    paddingBottom: "12px",
    backgroundColor: "transparent",
    '&:active': {
      backgroundColor: "transparent",
    }
  })
};

const BCMenuList = props => (
  <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="space-between"
  >
    {props.children}
  </Box>
);

const BCIconSelectSingle = ({ options, values, onChange, imagePrefix }) => {

  const IconOption = props => (
    <components.Option {...props}>
      <div style={{
        width: "100%",
        alignItems: 'center', display: 'flex',
      }}>
        <span>
          <img
            src={imagePrefix + props.data.icon}
            style={{ width: 24, marginRight: '0.5em' }}
            alt={props.data.label}
          />
        </span>
        <span style={{ top: "-0.1em", position: "relative" }}>{props.data.label}</span>
      </div>
    </components.Option>
  );

  const Placeholder = props => (
    <components.Placeholder{...props}>
      <Typography variant="body1" style={{ color: colors.body }}>
        브랜드명
      </Typography>
    </components.Placeholder>
  );

  return (
    <Box>
      <Select
        isSearchable={false}
        options={options}
        value={values}
        onChange={onChange}
        components={{ Option: IconOption, Placeholder: Placeholder }}
        styles={{
          control: base => ({
            borderBottom: "1px solid",
            borderColor: colors.border,
            display: "flex",
          }),
          option: (styles) => {
            return {
              ...styles,
              backgroundColor: colors.white,
              color: colors.primary
            };
          }
        }}
        classNamePrefix="select"
      />
    </Box>
  )
}

const ResultTag = props => {
  return (
    <Box
      style={{
        borderRadius: "16px",
        height: "26px",
        border: "solid 1px",
        borderColor: colors.point,
        boxSizing: "border-box",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      mx={"4px"}
      px={1}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="caption" style={{ color: colors.point }}>
          {props.text}
        </Typography>
      </Box>
    </Box>
  )
}

const BCResultTags = props => {
  return (
    <Box px={1} display="flex" justifyContent="center">
      {
        props.tags.map((e, i) => (
          <ResultTag key={i} text={e} />
        ))
      }
    </Box>
  )
}

const BCResultTitle = props => {
  return (
    <Box mt={11} textAlign="center" display="flex" justifyContent="center">
      <Typography variant="subtitle1" style={{ color: colors.white, fontWeight: "bold" }}>
        가장 알맞을 제품
      </Typography>
    </Box>
  )
}

const BCResultDescription = ({ text }) => {
  return (
    <Box mx={"20px"} style={{ textAlign: "center" }}>
      <Typography variant="body1" style={{ color: colors.body, fontSize: "14px" }}>
        {text}
      </Typography>
    </Box>
  )
}

const BCResultRecomendationText = (props) => {
  return (
    <Box mx={2}>
      <Typography variant="subtitle1" style={{ color: colors.primary, fontWeight: "bold" }}>
        이런 제품도 좋아요
      </Typography>
    </Box>
  )
}

const BCResultProductCard = ({ data }) => {
  return (
    <Box width={"42vw"} maxWidth={"270px"}>
      <a href={"https://thezam.co.kr/product/detail.html?" + data.shop_url} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>

        <Card variant="outlined" style={{ border: 0, borderRadius: "10px" }}>
          <CardMedia
            image={data.image_url}
            style={{
              height: 0,
              paddingTop: '131%',
            }}
          />
        </Card>
        <Box mt={1}>
          <Typography variant="body2" style={{ color: colors.body }}>
            {data.name}
          </Typography>
          <Typography variant="subtitle1" style={{ color: colors.primary, fontWeight: "bold" }}>
            {data.price}
          </Typography>
        </Box>
      </a>
    </Box>
  )
}

const BCResultProductTitleCard = ({ data }) => {
  return (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Box width={"44.8vw"} maxWidth={"286px"} mb={"11px"}>
        <a href={"https://thezam.co.kr/product/detail.html?" + data.shop_url} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
          <Card variant="outlined" style={{ border: 0, borderRadius: "10px" }}>
            <CardMedia
              image={data.image_url}
              style={{
                height: 0,
                paddingTop: '131%',
              }}
            />
          </Card>
        </a>
      </Box>
      <Box width={"100%"} style={{ textAlign: "center" }}>
        <Typography variant="h2" style={{ color: colors.primary }}>
          {data.name}
        </Typography>
      </Box>
    </Box>
  )
}

const BCResultActions = ({ copyURL }) => {
  return (
    <Box display="flex" width="100%" justifyContent="center" alignItems="center">
      <Box>
        <BCLink to="/">
          <Button>
            <Box
              display="flex"
              alignItems="center"
              px={1}
            >
              <img
                src="/assets/images/common/icon_refresh.svg"
                style={{ float: 'left' }} alt="refresh"
                width="32px"
              />
              <Typography variant="body1"
                style={{
                  marginLeft: '8px',
                  paddingBottom: '4px',
                  color: colors.body,
                }}>
                다시하기
              </Typography>
            </Box>
          </Button>
        </BCLink>
      </Box>
      <Box height={"32px"} width={"1px"} mx={3} style={{
        backgroundColor: colors.border
      }}
      />
      <Box>
        <Button
          className={"clipboard"}
          data-clipboard-text={copyURL}
        >
          <Box
            display="flex"
            alignItems="center"
            px={1}
          >
            <img
              src="/assets/images/common/icon_share.svg"
              style={{ float: 'left' }} alt="share"
              width="32px"
            />

            <Typography variant="body1"
              style={{
                marginLeft: '8px',
                paddingBottom: '4px',
                color: colors.body,
              }}>
              복사하기
            </Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  )
}

const ReviewSectionHeader = ({ text1, text2, icon }) => {
  return (
    <Box display="flex">
      <Box>
        <img width="48px" src={icon} alt="review" />
      </Box>
      <Box ml={0.5}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>{text1}</Typography>
        <Typography variant="body2" style={{ color: colors.body, lineHeight: "24px" }}>{text2}</Typography>
      </Box>
    </Box>

  )
}

export {
  BCHeader,
  BCContainer,

  BCHomeTitle,
  BCHomeDesc,
  BCHomeLoginDesc,

  BCQuestionsNumber,
  BCQuestionsTitle,
  BCQuestionsInfo,
  BCQuestionsSelect,

  BCReviewInfo,
  BCReviewTextArea,

  BCSizeHeader,
  BCSizeSelect,
  BCGallerySelect,
  BCIconSelectSingle,
  BCButtonSelect,
  BCBrandSelect,
  BCShowSlider,

  BCResultTitle,
  BCResultTags,
  BCResultDescription,
  BCResultRecomendationText,
  BCResultProductTitleCard,
  BCResultProductCard,
  BCResultActions,

  BCLink,
  BCStartButton,
  BCLoginButton,
  BCResultReviewButton,
  BCReviewSubmitButton,
  BCCouponButton,
  BCToResultButton,
  BCTalkButton,

  BCNavigation,
  BCFooter,

  ReviewSectionHeader,

  colors
}
