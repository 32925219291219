export const func = [
  { icon: 'func_0.png', value: 0, label: '볼륨이 굉장히 중요해요' },
  { icon: 'func_1.png', value: 1, label: '부담스럽지 않은\n볼륨을 원해요' },
  { icon: 'func_2.png', value: 2, label: '볼륨보다는 핏이 중요해요' },
  { icon: 'func_3.png', value: 3, label: '입지 않은 듯한\n속옷을 원해요' },
]

export const wire = [
  { value: 0, label: '네, 와이어가 있으면 좋겠어요.' },
  { value: 1, label: '아니요, 노와이어를 찾고 있습니다.' },
  { value: 2, label: '둘 다 상관 없어요!' },
]

export const design = [
  { icon: 'design_0.png', value: 0, label: '레이스 브라' },
  { icon: 'design_1.png', value: 1, label: '약간의 레이스 브라' },
  { icon: 'design_2.png', value: 2, label: '무지 디자인 브라' },
]