import {
  React,
} from 'react';

import {
  BCQuestionsTitle,
  BCQuestionsInfo,
  BCContainer,
  BCGallerySelect
} from "../../utils/elements"

import { sub } from "../../data/shape"
import { Box } from '@material-ui/core';
import { loadImage } from '../../utils/imageLoader';

export const useQShapeSubAnswers = () => {
  return []
}

export const checkQShapeSub = (answers) => {
  return true
}

export const QShapeSub = ({ answers, setAnswers }) => {
  loadImage(sub, "/assets/images/shape/")
  return (
    <>
      <BCQuestionsTitle>평소 속옷 착용 시<br />신경쓰이는 부분이 있으셨나요?</BCQuestionsTitle>
      <BCQuestionsInfo>
        최대 2개 선택 가능합니다!
      </BCQuestionsInfo>

      <Box mt={4} />

      <BCContainer>
        <BCGallerySelect
          isMulti={true}
          options={sub}
          values={answers}
          onChange={selectedOption => { setAnswers(selectedOption) }}
          imagePrefix={"/assets/images/shape/"}
          selectLimit={2}
        />
      </BCContainer>
    </>
  )
}