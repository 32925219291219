import {
  React,
} from 'react';

import {
  BCContainer,
  BCButtonSelect,
  BCQuestionsTitle,
} from "../../utils/elements"

import { wire } from "../../data/pref"
import { Box } from '@material-ui/core';

export const useQPrefWireAnswers = () => {
  return null
}

export const checkQPrefWire = (answers) => {
  return answers !== null
}

export const QPrefWire = ({ answers, setAnswers }) => {
  return (
    <>
      <BCQuestionsTitle>와이어가 있는 속옷을<br />선호하시나요?</BCQuestionsTitle>
      <Box mt={7} />

      <BCContainer>
        <BCButtonSelect
          isMulti={false}
          options={wire}
          values={answers}
          onChange={selectedOption => { setAnswers(selectedOption) }}
        />
      </BCContainer>
    </>
  )
}