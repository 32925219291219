import {
  createMuiTheme,
} from "@material-ui/core/styles";

const colors = {
  disabled: "#aeaeae",
  primary: "#202020",
  border: "#e5e5e5",
  white: "#ffffff",
  body: "#757575",
  title: "#424242",
  point: "#c09c7c",
  point2: "#422d1b",
}

export const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: `'Noto Sans KR', sans-serif;`
  },
  overrides: {
    MuiTypography: {
      "h1": {
        color: colors.primary,
        fontSize: "28px",
        fontWeight: "bold",
        lineHeight: "36px",
      },
      "h2": {
        color: colors.primary,
        fontSize: "20px",
        fontWeight: "bold",
        lineHeight: "26px",

      },
      "subtitle1": { // needs color, bold override
        color: colors.primary,
        fontSize: "18px",
        fontWeight: "normal",
        lineHeight: "24px",
      },
      "body1": { // needs color, bold override
        color: colors.primary,
        fontSize: "16px",
        fontWeight: "normal",
        lineHeight: "24px",
      },
      "body2": { // needs color, bold override
        color: colors.primary,
        fontSize: "14px",
        fontWeight: "normal",
        lineHeight: "20px",
      },
      "caption": {
        color: colors.primary,
        fontSize: "12px",
        fontWeight: "normal",
        lineHeight: "18px",
      },
    },
  }
});