import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import {
  ThemeProvider
} from "@material-ui/core/styles";
import { theme } from './bc-theme.js';
import './bc-style.css';

import App from './App';

import GA4React from "ga-4-react";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HashRouter>
        <App />
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-WTCJY7JJ82");
    ga4react.initialize()
      .then((ga4) => {
        console.log(ga4);
      });
  }, 4000);
} catch (err) { }