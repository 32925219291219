import {
  React,
  useContext,
  useState,
  useEffect
} from 'react'

import axios from 'axios'

import {
  BCContainer,
  BCResultReviewButton,
  BCResultTags,
  BCResultDescription,
  BCResultRecomendationText,
  BCResultProductCard,
  BCResultProductTitleCard,
  BCResultTitle,
  BCResultActions,
  BCTalkButton,
} from "../utils/elements"

import { QuestionsContext } from "../contexts/Questions"
import { Box, GridList, GridListTile } from '@material-ui/core'
import { Base64 } from 'js-base64'
import ClipboardJS from 'clipboard'
import ScriptTag from 'react-script-tag';
import Loading from './Loading'

const Result = ({ qType, setProgress, setShowHeader, isShared, queryParam, result, setResult, needSearch, setNeedSearch, eventOK }) => {
  useEffect(() => {
    setProgress(100);
  });

  const [requested, setRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(!isShared && needSearch);
  const [shareParam, setShareParam] = useState("");

  const qMap = useContext(QuestionsContext);

  useEffect(() => {
    let clipboard = new ClipboardJS('.clipboard').on(
      'success', function (e) {
        alert("클립보드에 주소가 복사되었습니다");
      }
    );
    return () => {
      clipboard.destroy()
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setShowHeader(true);
    }
  }, [])

  useEffect(() => {
    let searchParam;
    if (isShared && queryParam) {
      searchParam = queryParam;
    } else {
      let resultParam = { type: qType, values: {} };
      Object.keys(qMap[qType]).sort().forEach(e => {
        const q = qMap[qType][e];

        if (Array.isArray(q.A)) {
          resultParam.values[q.key] = q.A.map(a => a.value);
        } else if (q.A) {
          resultParam.values[q.key] = q.A.value;
        }
      });
      searchParam = resultParam;
    }

    searchParam.shared = isShared;
    setShareParam(Base64.encodeURI(JSON.stringify(searchParam)));

    if (requested || !needSearch) {
      return
    }
    setRequested(true);

    let URL_PREFIX = ""
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      URL_PREFIX = "http://localhost:5000"
    }
    axios.post(URL_PREFIX + '/api/search', searchParam)
      .then(function (response) {
        setResult(response.data);
        setNeedSearch(false);
        setTimeout(() => { setIsLoading(false); setShowHeader(true); }, 1500);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setResult, requested, setRequested, qMap, qType, isShared, queryParam, setShowHeader]);

  return (
    <>
      { isLoading ? <Loading setShowHeader={setShowHeader} /> : <>
        <Box style={{
          top: '50px',
          position: "absolute",
          zIndex: -1,
          height: "100vh",
          width: "100vw",
          maxWidth: "640px",
          backgroundImage: `url("/assets/images/common/result_bg.svg")`,
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat'
        }} />

        <BCResultTitle />
        <Box mt={"12px"} />
        <Box>
          {result && result.products.length > 0 ?
            <BCResultProductTitleCard data={result.products[0]} /> : null
          }
        </Box>
        <Box mt={1} />
        {result && result.category.length === 3 &&
          <BCResultTags tags={result.category.slice(0, 2).concat([result.query.size])} />
        }

        <Box mt={1} />
        {result && result.category.length === 3 &&
          <BCResultDescription text={result.category[2]} />
        }
        <Box mt={5} />
        <BCResultRecomendationText />

        <Box mt={"12px"} />
        <Box mx={"14px"}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
          }}>
          {result &&
            <GridList style={{
              flexWrap: 'nowrap',
              transform: 'translateZ(0)',
            }}>
              {
                result &&
                result.products.slice(1).map((e, i) => (
                  <GridListTile key={i} style={{
                    marginRight: "6px",
                    width: "fit-content",
                    height: "fit-content",
                  }}>
                    <BCResultProductCard data={e} />
                  </GridListTile>
                ))
              }
            </GridList>
          }
        </Box>

        <Box mt={5} />

        <BCResultActions
          copyURL={"https://lab.thezam.co.kr/#/shared?q=" + shareParam}
        />
        <Box mt={4} />

        <BCContainer>
          {
            qType && eventOK ?
              <>
                <BCResultReviewButton to="/complete" />
                <Box mt={"12px"} />
              </> :
              null
          }
          <BCTalkButton />
        </BCContainer>

        <Box mt={10} />
        <ScriptTag type="text/javascript" src="/utils/channel.js" />
      </>
      }
    </>
  )
}
export default Result