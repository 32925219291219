export const breast = [
  { icon: 'breast_0.png', value: 0, label: '컵과 가슴 사이 들뜸' },
  { icon: 'breast_1.png', value: 1, label: '적당히 알맞음' },
  { icon: 'breast_2.png', value: 2, label: '컵이 가슴을 누름' },
]

export const strap = [
  { icon: 'strap_0.png', value: 0, label: '어깨를 파고듦' },
  { icon: 'strap_1.png', value: 1, label: '적당히 알맞음' },
  { icon: 'strap_2.png', value: 2, label: '자주 흘러내림' },
]

export const band = [
  { icon: 'band_0.png', value: 0, label: '위로 당겨 올라감' },
  { icon: 'band_1.png', value: 1, label: '수평을 이룸' },
  { icon: 'band_2.png', value: 2, label: '매우 타이트함' },
]