import {
  React
} from 'react'
import Result from './Result';
import { Base64 } from 'js-base64';
import { Redirect, Route, useLocation } from "react-router-dom";

const Shared = ({ qType, setProgress, setShowHeader, setNeedSearch, needSearch, result, setResult }) => {
  const search = useLocation().search;
  const encodedQueryParam = new URLSearchParams(search).get('q');

  if (Base64.isValid(encodedQueryParam)) {
    let queryParamString = Base64.decode(encodedQueryParam);
    let queryParamObj;
    try {
      queryParamObj = JSON.parse(queryParamString);
      return (
        <Result qType={qType} setProgress={setProgress} isShared={true} queryParam={queryParamObj} setShowHeader={setShowHeader}
          setNeedSearch={setNeedSearch} needSearch={needSearch} result={result} setResult={setResult}
        />
      )
    } catch (e) {
      console.log("malformed query param")
    }
  }

  return (
    <Route>
      <Redirect to={{ pathname: '/' }} />
    </Route>
  )

}
export default Shared