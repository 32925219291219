export const bust = [
  { value: 65, label: 65 },
  { value: 70, label: 70 },
  { value: 75, label: 75 },
  { value: 80, label: 80 },
  { value: 85, label: 85 },
  { value: 90, label: 90 },
]

export const cup = [
  { value: 0, label: 'AA' },
  { value: 1, label: 'A' },
  { value: 2, label: 'B' },
  { value: 3, label: 'C' },
  { value: 4, label: 'D' },
  { value: 5, label: 'E' },
  { value: 6, label: 'F' },
]