import {
  React,
  useState
} from 'react';

import {
  Box
} from '@material-ui/core';

import { cup, bust } from "../../data/size"

import {
  BCQuestionsTitle,
  BCSizeHeader,
  BCSizeSelect,
} from "../../utils/elements"

export const useQSizeAnswers = () => {
  return {
    value: {
      bust: null,
      cup: null,
    },
    checked: false
  }
}

export const checkQSize = (answers) => {
  return answers.value.bust !== null && answers.value.cup !== null && answers.checked
}

export const QSize = ({ answers, setAnswers }) => {
  const [isBust, setIsBust] = useState(true);

  return (
    <>
      <BCQuestionsTitle>지금 착용 중인<br />속옷의 사이즈를 알려주세요.</BCQuestionsTitle>
      <Box mt={3} />

      <BCSizeHeader
        isBust={isBust}
        setIsBust={setIsBust}
        answers={answers}
        setAnswers={setAnswers}
      />

      <Box mt={3} mx={3}>
        {isBust ?
          <BCSizeSelect
            isMulti={false}
            options={bust}
            values={answers.value.bust}
            onChange={selectedOption => {
              setIsBust(false);
              setAnswers({
                value: {
                  bust: selectedOption, cup: answers.value.cup
                },
                checked: true
              })
            }}
            selectLimit={1}
          />
          :
          <BCSizeSelect
            isMulti={false}
            options={cup}
            values={answers.value.cup}
            onChange={selectedOption => {
              setAnswers({
                value: {
                  cup: selectedOption, bust: answers.value.bust
                },
                checked: true
              })
            }}
            selectLimit={1}
          />
        }
      </Box>
    </>
  )
}