import {
  React,
} from 'react';

import {
  BCContainer,
  BCShowSlider,
  BCQuestionsTitle
} from "../../utils/elements"

import { band } from "../../data/fit"
import { Box } from '@material-ui/core';
import { loadImage } from '../../utils/imageLoader';

export const useQFitBandAnswers = () => {
  return band[0]
}

export const checkQFitBand = (answers) => {
  return answers !== null
}

export const QFitBand = ({ answers, setAnswers }) => {
  loadImage(band, "/assets/images/fit/");
  return (
    <>
      <BCQuestionsTitle>몸통을 감싸는 가슴 아래 밴드는<br />어떤 모습인가요?</BCQuestionsTitle>
      <Box mt={2} />

      <BCContainer>
        <BCShowSlider
          choices={band}
          current={answers ? answers : band[0]}
          onChange={(e, value) => { setAnswers(band[value]) }}
          imagePrefix={"/assets/images/fit/"}
        />
      </BCContainer>
    </>
  )
}