export const main = [
  { icon: 'main_1.png', value: 1, label: '종 모양 가슴' },
  { icon: 'main_5.png', value: 5, label: '둥근 가슴' },
  { icon: 'main_6.png', value: 6, label: '작은 가슴' },
  { icon: 'main_4.png', value: 4, label: '처진 가슴' },
  { icon: 'main_2.png', value: 2, label: '벌어진 가슴' },
  { icon: 'main_3.png', value: 3, label: '퍼진 / 넓은 가슴' },
  { icon: 'main_0.png', value: 0, label: '양쪽 사이즈가 다른 가슴' },
]

export const sub = [
  { icon: 'sub_1.png', value: 1, label: '새가슴 체형' },
  { icon: 'sub_2.png', value: 2, label: '등살 발달' },
  { icon: 'sub_3.png', value: 3, label: '윗가슴 부족' },
  { icon: 'sub_0.png', value: 0, label: '부유방 발달' },
]

export const posed = [
  { icon: 'posed_0.jpg', value: 0, label: '넓게 분포된 형태' },
  { icon: 'posed_1.jpg', value: 1, label: '좁게 붙어있는 형태' },
]