import {
  React,
  useState,
  useContext,
  useEffect,
} from 'react'

import { QuestionsContext } from '../contexts/Questions';

import {
  BCQuestionsNumber,
  BCFooter,
  BCNavigation,
} from "../utils/elements"

const Questions = ({ qType, setProgress, setShowHeader }) => {

  const qMap = useContext(QuestionsContext);
  const [qNum, setQNum] = useState(2);
  const { Q, A, setA, check } = qMap[qType][qNum];

  const next = qNum + 1;
  const back = qNum - 1;
  const isLast = next > Object.keys(qMap[qType]).length + 1;
  const isFirst = qNum === 2;

  useEffect(() => {
    setShowHeader(true);
    setProgress((qNum - 1) / (Object.keys(qMap[qType]).length + 1) * 100);
  });

  return (
    <>
      <BCQuestionsNumber>Q{qNum}.</BCQuestionsNumber>

      <Q answers={A} setAnswers={setA} qNum={qNum} />

      <BCFooter>
        <BCNavigation
          backDisabled={false}
          nextDisabled={!check(A)}
          backLink={isFirst ?
            "/" : null
          }
          backAction={isFirst ?
            null
            : () => { setQNum(back) }
          }
          nextLink={isLast ?
            "/result" : null
          }
          nextAction={isLast ?
            null
            : () => { setQNum(next) }
          }
        />
      </BCFooter>
    </>
  )
}
export default Questions