import {
  React,
} from 'react';

import {
  BCContainer,
  BCShowSlider,
  BCQuestionsTitle
} from "../../utils/elements"

import { breast } from "../../data/fit"
import { Box } from '@material-ui/core';
import { loadImage } from '../../utils/imageLoader';

export const useQFitBreastAnswers = () => {
  return breast[0]
}

export const checkQFitBrest = (answers) => {
  return answers !== null
}

export const QFitBreast = ({ answers, setAnswers }) => {
  loadImage(breast, "/assets/images/fit/")
  return (
    <>
      <BCQuestionsTitle>속옷을 입은 후<br />윗가슴은 어떤 모습인가요?</BCQuestionsTitle>
      <Box mt={2} />

      <BCContainer>
        <BCShowSlider
          choices={breast}
          current={answers ? answers : breast[0]}
          onChange={(e, value) => { setAnswers(breast[value]) }}
          imagePrefix={"/assets/images/fit/"}
        />
      </BCContainer>
    </>
  )
}