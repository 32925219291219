import {
  React, useEffect,
} from 'react'

import {
  BCQuestionsNumber,
  BCContainer,
  BCQuestionsTitle,
  BCQuestionsSelect,
  BCFooter,
  BCNavigation,
} from "../utils/elements"

import { qTypes } from "../data/qTypes"
import { Box } from '@material-ui/core'

const Start = ({ qType, setQType, setProgress, setShowHeader, setNeedSearch, setEventOK }) => {
  useEffect(() => {
    setShowHeader(true);
    setProgress(2);
    setNeedSearch(true);
    setEventOK(true);
  });
  return (
    <>
      <BCQuestionsNumber>Q1.</BCQuestionsNumber>
      <BCQuestionsTitle>어떤 기준의<br />제품 추천이 필요하세요?</BCQuestionsTitle>
      <Box mt={8} />

      <BCContainer>
        <BCQuestionsSelect
          options={qTypes}
          values={qTypes.find(q => q.value === qType)}
          imagePrefix="/assets/images/qTypes/"
          onChange={selectedOption => { setQType(selectedOption.value); }}
        >
        </BCQuestionsSelect>
      </BCContainer>

      <BCFooter>

        <BCNavigation
          backDisabled={false}
          backLink={"/"}
          nextDisabled={qType === ""}
          nextLink={"/questions/"}
        />

      </BCFooter>


    </>
  )
}
export default Start