import {
  React,
} from 'react';

import {
  BCContainer,
  BCQuestionsTitle,
  BCGallerySelect,
} from "../../utils/elements"

import { design } from "../../data/pref"
import { Box } from '@material-ui/core';

export const useQPrefDesignAnswers = () => {
  return null
}

export const checkQPrefDesign = (answers) => {
  return answers !== null
}

export const QPrefDesign = ({ answers, setAnswers }) => {
  return (
    <>
      <BCQuestionsTitle>어떤 디자인의 속옷을<br />찾고 계신가요?</BCQuestionsTitle>
      <Box mt={7} />

      <BCContainer>
        <BCGallerySelect
          isMulti={false}
          options={design}
          values={answers}
          onChange={selectedOption => { setAnswers(selectedOption) }}
          imagePrefix={"/assets/images/pref/"}
          selectLimit={1}
        />
      </BCContainer>
    </>
  )
}