import {
  React,
} from 'react';

import {
  BCContainer,
  BCGallerySelect,
  BCQuestionsTitle,
  BCQuestionsInfo,
} from "../../utils/elements"

import { main } from "../../data/shape"
import { Box } from '@material-ui/core';
import { loadImage } from '../../utils/imageLoader';

export const useQShapeMainAnswers = () => {
  return []
}

export const checkQShapeMain = (answers) => {
  return answers.length > 0
}

export const QShapeMain = ({ answers, setAnswers }) => {
  loadImage(main, "/assets/images/shape/")
  return (
    <>
      <BCQuestionsTitle>당신의 가슴은<br />어떤 형태인가요?</BCQuestionsTitle>
      <BCQuestionsInfo>
        최대 2개 선택 가능합니다!
      </BCQuestionsInfo>

      <Box mt={4} />

      <BCContainer>
        <BCGallerySelect
          isMulti={true}
          options={main}
          values={answers}
          onChange={selectedOption => { setAnswers(selectedOption) }}
          imagePrefix={"/assets/images/shape/"}
          selectLimit={2}
        />
      </BCContainer>
    </>
  )
}